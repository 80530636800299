<template>
  <badge :type="getBadgeType()">
    {{ status }}
  </badge>
</template>

<script>
export default {
  name: "request-log-status-badge",

  components: {},

  mixins: [],

  props: ["status"],

  computed: {},

  methods: {
    getBadgeType() {
      switch (this.status) {
        case 200:
          return "success";
        case 422:
          return "warning";
        default:
          return "danger";
      }
    },
  },

  mounted() {},

  watch: {},
};
</script>
