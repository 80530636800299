<template>
  <div class="container-fluid">
    <span class="resize-loading" v-if="!requestLog">
      <span class="loader"></span>
    </span>
    <div class="row w-100">
      <div :class="isError ? 'col-md-6' : 'col-md-12'">
        <view-estimate-component
          :form-classes="isError ? 'col-lg-12' : 'col-lg-5'"
          :request-log="requestLog"
          read-only
        />
      </div>
      <div class="col-md-6" v-if="isError">
        <card>
          <div class="tab-pane-inner">
            <h3>{{ $t("COMMON.ERROR") }}</h3>
            <div class="list-table-inner-body">
              <vue-json-pretty
                :collapsed-on-click-brackets="false"
                :data="requestLog.result"
                :showDoubleQuotes="false"
              />
            </div>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import ViewEstimateComponent from "@/views/Pages/VehiclesModule/GetVehicleEstimate/components/ViewEstimateComponent.vue";
import Card from "@/components/Cards/Card.vue";
import VueJsonPretty from "vue-json-pretty";
import "vue-json-pretty/lib/styles.css";
import requestErrorMixin from "@/mixins/request-error-mixin";

export default {
  name: "view-request-log-component",

  components: {
    ViewEstimateComponent,
    Card,
    VueJsonPretty,
  },
  mixins: [requestErrorMixin],

  props: {
    requestLogId: {
      type: Number | String,
      required: true,
    },
  },

  data() {
    return {
      requestLog: null,
    };
  },

  computed: {
    isError: function () {
      return this.requestLog && this.requestLog.status_code != 200;
    },
  },

  created() {
    this.get();
  },

  methods: {
    async get() {
      this.loading = true;
      try {
        await this.$store.dispatch("requestLogs/get", this.requestLogId);
        this.requestLog = this.$store.getters["requestLogs/requestLog"];
        this.$emit("estimateLoaded", this.estimate);
      } catch (error) {
        this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: `Oops, something went wrong!`,
        });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
